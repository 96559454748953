import React from 'react'
import Details from '../components/Details';
import ServiceForm from '../components/ServiceForm';

const Contact = () => {
  return (
    <ServiceForm />
  )
}

export default Contact