import React, { useState, useEffect} from 'react';
import { Container, Grid, Typography, Card, CardContent, CardMedia, Button, Box, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom'

const services = [
  {
    title: 'Airport Transportation',
    description: 'Enjoy a seamless journey to and from the airport with our dependable airport transportation service. We provide timely pickup and drop-off, comfortable vehicles, and 24/7 availability.',
    details: [
      'Timely Pickup and Drop-off: Count on us to ensure you arrive on schedule, every time.',
      'Comfortable Vehicles: Travel in comfort with our well-maintained fleet, designed to enhance your experience.',
      'Meet & Greet Service: For an extra touch of convenience, we offer a meet and greet service with a customized sign displaying your name or company logo.',
      '24/7 Availability: We’re available around the clock to accommodate your travel needs.',
    ],
    image: require('./../assets/airport.png'),
  },
  {
    title: 'Charter Transportation',
    description: 'When you need flexible and reliable transportation for your group, our charter service is the perfect choice.',
    details: [
      'Custom Itineraries: Create a travel plan tailored to your specific needs and schedule.',
      'Spacious Vehicles: Our fleet can accommodate groups of any size, ensuring comfort for all passengers.',
      'Professional Drivers: Our experienced drivers provide a safe and smooth journey, no matter the destination.',
      'Ideal for Any Occasion: Whether it’s a corporate event, wedding, or special outing, our charter service is designed to meet your needs.',
    ],
    image: require('./../assets/charter.png'),
  },
  {
    title: 'Group Transportation',
    description: 'Need to move a group? Our group transportation service is the perfect solution for any occasion.',
    details: [
      'Spacious Vehicles: Comfortably accommodate groups of any size.',
      'Custom Travel Plans: Tailored itineraries to fit your group’s schedule and needs.',
      'Reliable Service: Punctual and professional drivers to ensure a smooth journey.',
      'Ideal for All Events: Perfect for corporate outings, family gatherings, weddings, and more.',
    ],
    image: require('./../assets/group_transport.png'),
  },
  {
    title: 'City-to-City Transportation',
    description: 'Travel between cities with ease and comfort. Our city-to-city transportation service offers competitive pricing and flexible scheduling.',
    details: [
      'Comfortable Rides: Enjoy spacious, well-maintained vehicles for a smooth journey.',
      'Door-to-Door Service: We pick you up and drop you off exactly where you need to be.',
      'Experienced Drivers: Skilled in long-distance travel, ensuring your safety and comfort.',
      'Flexible Scheduling: Travel when it suits you with our flexible booking options.',
      'Competitive Pricing: Affordable rates without sacrificing quality.',
    ],
    image: require('./../assets/route66.png'),
  },
];

const ServiceCard = ({ service }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: 6,
        },
      }}
    >
      <Box sx={{ position: 'relative' }}>
        <CardMedia
          component="img"
          alt={service.title}
          height="200"
          image={service.image}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: '#fff',
            padding: '8px',
          }}
        >
          <Typography variant="h6">{service.title}</Typography>
        </Box>
      </Box>
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {service.description}
        </Typography>
        <Box
          sx={{
            maxHeight: expanded ? 'none' : '60px',
            overflow: 'hidden',
            transition: 'max-height 0.3s ease-in-out',
          }}
        >
          {service.details.map((detail, index) => (
            <Typography key={index} variant="body2" color="textSecondary">
              • {detail}
            </Typography>
          ))}
        </Box>
      </CardContent>
      <Box sx={{ padding: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button component={Link}
         to={'/contact'}
          variant="contained"
          color="warning"
          fullWidth
          sx={{
            fontWeight: 'bold',
            backgroundColor: '#d87a16',
            transition: 'background-color 0.3s ease',
            '&:hover': {
              backgroundColor: '#b36911',
            },
          }}
        >
          Request your quote
        </Button>
        <IconButton
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          sx={{ marginLeft: 2, transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
        >
          <ExpandMoreIcon />
        </IconButton>
      </Box>
    </Card>
  );
};

const ServiceList = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component is mounted
  }, []);

  return (
    <Container sx={{ paddingY: 4 }}>
      <Typography variant="h4" align="center" sx={{ fontWeight: 'bold', marginBottom: 4, color: '#333' }}>
        Services
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {services.map((service, index) => (
          <Grid item xs={12} md={6} key={index}>
            <ServiceCard service={service} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ServiceList;
