import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  List,
  ListItem,
  Typography,
  styled,
  ListItemButton,
  ListItemText,
  Drawer,
  IconButton,
  Icon,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

// Personalization
const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
});

const ListMenu = styled(List)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('sm')]: {
    display: 'flex',
  },
}));

// Routes
const itemList = [
  {
    text: 'Home',
    to: '/',
  },
  {
    text: 'Vehicles',
    to: '/vehicles',
  },
  {
    text: 'Tours',
    to: '/our-tours',
  },
  {
    text: 'Services',
    to: '/services',
  },
];

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <AppBar component="nav" position="sticky" sx={{ backgroundColor: 'white' }} elevation={0}>
      <StyledToolbar>
      <Box component={Link} to="/" sx={{ display: 'flex', alignItems: 'center' }}>
          <Icon sx={{ height: 80, width: 80 , padding: 2}}>
            <img
              src={require('./../assets/logomenu.png')} // Adjust the path to your image file
              alt="Logo"
              style={{ height: '100%', width: '100%' }}
            />
          </Icon>
        </Box>
        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
            <MenuIcon sx={{ color: 'black' }}/>
          </IconButton>
        </Box>
        <ListMenu>
          {itemList.map((item) => (
            <ListItem key={item.text}>
              <ListItemButton
                component={Link}
                to={item.to}
                sx={{
                  color: '#000000',
                  whiteSpace: 'nowrap',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: '#1e2a5a',
                  },
                }}
              >
                <ListItemText
                  primary={item.text}
                  primaryTypographyProps={{ noWrap: true }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </ListMenu>
      </StyledToolbar>
      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
        <List>
          {itemList.map((item) => (
            <ListItem key={item.text} button component={Link} to={item.to} onClick={handleDrawerToggle}>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </AppBar>
  );
};

export default Navbar;
