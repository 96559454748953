import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, MenuItem, Grid, Typography, Box, Collapse } from '@mui/material';
import axios from 'axios';

const services = [
  'Airport Transportation service',
  'Charter Service (Hourly service)',
  'Group Transportation',
  'City to City',
  'Las Vegas Strip Night City Tour',
  'Fremont Street - Downtown Las Vegas',
  'Shopping Tour',
  'Red Rock Canyon Tour',
  'Grand Canyon West Rim Tour',
  'Hoover Dam Tour',
  '66 Route Tour',
  'Mount Charleston',
  'Other',
];

const transportationTypes = ['Hourly', 'Point to point'];

const ServiceForm = () => {
  const initialFormData = {
    name: '',
    phone: '',
    email: '',
    service: '',
    otherService: '',
    date: '',
    transportationType: '',
    hours: '',
    additionalDetails: '',
  };

  const [formData, setFormData] = useState(initialFormData);


  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component is mounted
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
  
    e.preventDefault();

    // Validation logic
    const errors = {};
  
    // Validate required fields
    if (!formData.name.trim()) {
      errors.name = 'Name is required';
    }
  
    if (!formData.phone.trim()) {
      errors.phone = 'Phone is required';
    }
  
    if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Valid email is required';
    }
  
    if (!formData.service.trim()) {
      errors.service = 'Service is required';
    }
  
    if (formData.service === 'Other' && !formData.otherService.trim()) {
      errors.otherService = 'Please specify your service';
    }
  
    if (!formData.date.trim()) {
      errors.date = 'Date is required';
    }
  
    if (!formData.transportationType.trim()) {
      errors.transportationType = 'Transportation Type is required';
    }
  
    if (formData.transportationType === 'Hourly' && (!formData.hours.trim() || isNaN(formData.hours) || formData.hours <= 0)) {
      errors.hours = 'Please enter a valid number of hours';
    }
  
    if (Object.keys(errors).length > 0) {
      // Display validation errors (for simplicity, using alert here)
      alert('Please fix the errors:\n' + Object.values(errors).join('\n'));
      return;
    }
  
    // If validation passes, proceed with form submission
    const finalData = { ...formData };
  
    console.log('Form submitted:', finalData);
  
    // Prepare form data for submission
    const form = new FormData();
    form.append("name", formData.name);
    form.append("phone", formData.phone);
    form.append("email", formData.email);
    form.append("service", formData.service);
    form.append("otherService", formData.otherService);
    form.append("date", formData.date);
    form.append("transportationType", formData.transportationType);
    form.append("hours", formData.hours);
    form.append("additionalDetails", formData.additionalDetails);

    // Submit form data via axios
    axios({
      url: "https://www.glauco.website/PHPMailer/send_email_vtransportation.php",
      method: "post",
      data: form,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((responseSubmit) => {
        console.log("sending...", responseSubmit);
         
    const status = responseSubmit.status;

    if (status === 200) {
      console.log("Sended");
       setFormData(initialFormData);
    } else {
      console.log("NOT Sended");
    }
      })
      .catch((errorSubmit) => {
        console.log("error...", errorSubmit);
        //setLoading(false);
        //showToastMessageError();
      });
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 4 }}>
        <Typography variant="body1" align="center" sx={{ marginBottom: 4 }}>
          Please fill out the form below and one of our representatives will get back to you with the best prices. Thank you for your interest!
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="E-mail"
                name="email"
                value={formData.email}
                onChange={handleChange}
                type="email"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                label="Service / Tour of interest"
                name="service"
                value={formData.service}
                onChange={handleChange}
                required
              >
                {services.map((service, index) => (
                  <MenuItem key={index} value={service}>
                    {service}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <Collapse in={formData.service === 'Other'}>
                <TextField
                  fullWidth
                  label="Please specify your service"
                  name="otherService"
                  value={formData.otherService}
                  onChange={handleChange}
                  required={formData.service === 'Other'}
                />
              </Collapse>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Date of the service"
                name="date"
                type="date"
                value={formData.date}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                label="Transportation Type"
                name="transportationType"
                value={formData.transportationType}
                onChange={handleChange}
                required
              >
                {transportationTypes.map((type, index) => (
                  <MenuItem key={index} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <Collapse in={formData.transportationType === 'Hourly'}>
                <TextField
                  fullWidth
                  label="Please specify the number of hours"
                  name="hours"
                  value={formData.hours}
                  onChange={handleChange}
                  required={formData.transportationType === 'Hourly'}
                />
              </Collapse>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Additional Details: (please add any additional requests or details)"
                name="additionalDetails"
                value={formData.additionalDetails}
                onChange={handleChange}
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: 4 }}>
              <Button
                variant="contained"
                type="submit"
                color="warning"
                fullWidth
                sx={{
                  fontWeight: "bold",
                  borderRadius: 1,
                  backgroundColor: "#d87a16",
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default ServiceForm;
