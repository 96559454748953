import React from "react";
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Box,
} from "@mui/material";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import GroupIcon from "@mui/icons-material/Group";
import TourIcon from "@mui/icons-material/Tour";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";

const services = [
  {
    title: "Airport Transfers",
    description:
      "Swift and seamless rides to and from McCarran International Airport.",
    icon: <FlightTakeoffIcon sx={{ fontSize: 40, color: "#d87a16" }} />,
  },
  {
    title: "Charter Services",
    description: "Customized transportation for groups of any size.",
    icon: <GroupIcon sx={{ fontSize: 40, color: "#d87a16" }} />,
  },
  {
    title: "City Tours",
    description: "Explore Las Vegas with our knowledgeable drivers.",
    icon: <TourIcon sx={{ fontSize: 40, color: "#d87a16" }} />,
  },
  {
    title: "Private Transportation",
    description:
      "Discreet and luxurious rides for VIPs and business travelers.",
    icon: <BusinessCenterIcon sx={{ fontSize: 40, color: "#d87a16" }} />,
  },
];

const Header = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      {/* Seção de Boas-Vindas */}
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <Typography variant="h4" component="h1" fontWeight="bold">
          Welcome to V Transportation
        </Typography>
        <Typography variant="h4" component="h1" fontWeight="bold" gutterBottom>
          Your Premier Transportation Solution in
          Las Vegas
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Experience the ultimate in comfort, reliability, and luxury with V
          Transportation. Whether you're heading to or from the airport,
          exploring the city's vibrant attractions, or in need of private or
          group transportation, we are here to serve you. Our professional
          drivers and top-of-the-line vehicles ensure a smooth, stress-free
          journey every time.
        </Typography>
      </Box>

      {/* Seção de Serviços com Ícones */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" component="h2" fontWeight="bold" gutterBottom>
          Our Services Include:
        </Typography>
        <Grid container spacing={4}>
          {services.map((service, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card
                sx={{
                  borderRadius: 3,
                  boxShadow: 3,
                  height: "220px", // Defina uma altura fixa
                  textAlign: "center",
                  padding: 2,
                  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: 6,
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80px",
                  }}
                >
                  {service.icon}
                </Box>
                <CardContent>
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    {service.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {service.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Seção de Contato */}
      <Box sx={{ textAlign: "center", mt: 6 }}>
        <Typography variant="h5" component="h2" fontWeight="bold" gutterBottom>
          Book your ride today and travel with confidence.
        </Typography>
        <Typography variant="body1" color="textSecondary">
          At V Transportation, your journey is our priority.
        </Typography>
        <Box sx={{ textAlign: "center", mt: 4 }}>
            <Typography variant="body1" color="textSecondary">
                Contact us to schedule your transportation or to learn more about our
                services.
            </Typography>
            <Typography variant="body1" color="textSecondary">
                We look forward to serving you in Las Vegas!{" "}
            </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Header;
