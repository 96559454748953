import React from 'react'
import GetInTouch from '../components/GetInTouch';
import GetStarted from '../components/GetStarted';
import Header from '../components/Header';
import FleetList from '../components/FleetList';
import TourList from '../components/TourList';
import ServiceList from '../components/ServiceList';
import ServiceForm from '../components/ServiceForm.jsx';

const Home = () => {
  return (
    <>
    <Header />
    <FleetList />
    <TourList />
    <ServiceList />
    <ServiceForm />
    </>

  )
}

export default Home