import React, { useState } from "react";
import {
  Box,
  Stack,
  styled,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import FooterTitle from "./FooterTitle";
import FooterLink from "./FooterLink";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Footer = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const StackColumn = styled(Stack)(() => ({
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    flex: 1,
    gap: 8,
    textAlign: "center",
  }));

  const BoxRow = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#ededed",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: 30,
    },
  }));

  return (
    <BoxRow
      component="footer"
      sx={{
        py: 4,
        px: 2,
      }}
    >
      <StackColumn>
        <FooterTitle text={"address"} />
        <FooterLink text={"6467 Haypress Ct"} />
        <FooterLink text={"Las Vegas"} />
        <FooterLink text={"89141 Nevada, USA"} />
      </StackColumn>

      <StackColumn>
        <FooterTitle text={"Privacy Policy"} onClick={handleClickOpen} />
      </StackColumn>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          Privacy Policy
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <DialogContentText id="alert-dialog-slide-description">
            This Privacy Policy describes how we collect, use, and share your
            personal information when you use our services. By using our
            services, you agree to the collection and use of your information in
            accordance with this policy.
          </DialogContentText>

          <DialogTitle>Information Collection</DialogTitle>
          <DialogContentText>
            We may collect personal information that you provide to us directly
            when using our services, such as your name, email address, phone
            number, and payment information.
          </DialogContentText>

          <DialogTitle>Use of Information</DialogTitle>
          <DialogContentText>
            We use your personal information to provide, maintain, and improve
            our services, to process your transactions, respond to support
            requests, and to send communications related to our services.
          </DialogContentText>

          <DialogTitle>Information Sharing</DialogTitle>
          <DialogContentText>
            We do not share your personal information with third parties except
            when necessary to provide our services, comply with legal
            obligations, or protect our rights.
          </DialogContentText>

          <DialogTitle>Security</DialogTitle>
          <DialogContentText>
            We implement security measures to protect your information against
            unauthorized access, alteration, disclosure, or destruction.
          </DialogContentText>

          <DialogTitle>Changes to this Policy</DialogTitle>
          <DialogContentText>
            This Privacy Policy is subject to change. Any changes will be posted
            on this page and will take effect immediately upon publication.
          </DialogContentText>

          <DialogTitle>Contact Us</DialogTitle>
          <DialogContentText>
            If you have any questions about this Privacy Policy, please contact
            us.
          </DialogContentText>

          <DialogContentText sx={{
              
              marginTop: 4,
             bottom: 10,
            }}>
            This Privacy Policy was last updated on 04/13/24.
          </DialogContentText>
        </DialogContent>

        <DialogActions></DialogActions>
      </Dialog>
    </BoxRow>
  );
};

export default Footer;
