import React, { useEffect } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import CarCard from './CarCard';

const cars = [
  {
    title: 'Shuttle Sprinter',
    description: 'Leather Captain Seats - 110V-USB chargers',
    passengers: '1-15 passengers',
    luggage: '1-8 luggage',
    images: [
      require('./../assets/sprinter_shuttle_1.jpeg'),
      require('./../assets/sprinter_shuttle_2.jpeg'),
      require('./../assets/sprinter_shuttle_3.jpg'),
    ],
  },
  {
    title: 'Limo Sprinter',
    description: 'Leather Captain Seats - 110V-USB chargers',
    passengers: '1-12 passengers',
    luggage: '1-8 luggage',
    images: [
      require('./../assets/sprinter_limo_1.jpeg'),
      require('./../assets/sprinter_limo_2.jpeg'),
      require('./../assets/sprinter_limo_3.jpeg'),
      require('./../assets/sprinter_limo_4.jpeg'),
    ],
  },
  {
    title: 'Executive Sprinter',
    description: 'Leather Captain Seats - 110V-USB chargers',
    passengers: '1-14 passengers',
    luggage: '1-8 luggage',
    images: [
      require('./../assets/sprinter_executive_1.jpeg'),
      require('./../assets/sprinter_executive_2.jpeg'),
      require('./../assets/sprinter_executive_3.jpeg'),
    ],
  },
  {
    title: 'Minibus',
    description: 'Leather Captain Seats - 110V-USB chargers',
    passengers: '1-25 passengers',
    luggage: '1-21 luggage',
    images: [
      require('./../assets/minibus_1.jpeg'),
      require('./../assets/minibus_2.jpeg'),
      require('./../assets/minibus_3.jpeg'),
    ],
  },
];

const FleetList = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component is mounted
  }, []);

    return (
        <Container sx={{ paddingBottom: 9 , paddingTop:4}}>
      <Typography variant="h4" align="center" sx={{ fontWeight: 'bold', marginBottom: 4, color: '#333' }}>
        Our Fleet
      </Typography>
      <Grid container spacing={8} justifyContent="center"> {/* Increased spacing to 5 */}
        {cars.map((car, index) => (
          <Grid item xs={12} md={6} key={index}>
            <CarCard car={car} />
          </Grid>
        ))}
      </Grid>
    </Container>
      );
    };

export default FleetList;
